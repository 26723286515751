import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';

import { brandState, appState, albumsState, userState } from '../Recoil/atoms';

import { Box, Image, Card, CardHeader, CardBody, CardFooter, Stack, Button, useTheme, Tag, Badge, Text, HStack } from '@chakra-ui/react';

import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

const Albums = () => {

    const { logout } = useContext(AuthContext);

    const [brandData, setBrandData] = useRecoilState(brandState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [currentAlbums, setCurrentAlbums] = useRecoilState(albumsState);
    const [currentUser, setCurrentUser] = useRecoilState(userState);

    const navigate = useNavigate();

    const fetchAlbums = async () => {
        try {
            setLoading(true);
            const albums = await apiHandler.getPublicAlbums(brandData?.type, brandData?.result.id, logout);
            const sortedAlbums = albums.sort((a, b) => new Date(b.start_date_time) - new Date(a.start_date_time));
            await setCurrentAlbums(sortedAlbums);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAlbums();
    }, [brandData]);

    useEffect(() => {
        fetchAlbums();
    }, []);


    return (
        <>
            <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" alignItems="center">
                {
                    currentAlbums?.length ? currentAlbums.map(album => {
                        const weekDay = dateHandler.toEsDate(album.start_date_time, 'weekDay');
                        const day = dateHandler.toEsDate(album.start_date_time, 'day');
                        const month = dateHandler.toEsDate(album.start_date_time, 'month');
                        const year = dateHandler.toEsDate(album.start_date_time, 'year');

                        const FormattedDate = () => {
                            return (
                                <span>
                                    {weekDay} <strong>{day}</strong> {month}. {year}
                                </span>
                            );
                        }

                        const eventFlyer = album?.flyer;

                        return (
                            <Box position="relative" w="100%" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" display='flex' key={album.id} onClick={() => {navigate(`/album/${album.id}`)}} opacity={album.active ? 1 : 0.5}>
                                {
                                    (eventFlyer && !utilHandlers.isMp4(eventFlyer)) && <>
                                        <Image src={eventFlyer} maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{ aspectRatio: 1080 / 1350 }}/>
                                    </>
                                }
                                {
                                    (eventFlyer && utilHandlers.isMp4(eventFlyer)) && <>
                                        <Box as="video" autoPlay loop muted playsInline maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{ aspectRatio: 1080 / 1350 }}>
                                            <source src={eventFlyer} type="video/mp4" />
                                        </Box>
                                    </>
                                }
                                <Box padding="10px" display="flex" gap="5px" alignItems="start" flexDirection="column">
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Tag bgColor="blackScheme.300" color="whiteScheme.300" fontSize="xs"><FormattedDate/></Tag>
                                    </Box>
                                    <Box display="flex" flexDirection="column" gap="10px">
                                        <Text fontSize="2xl" fontWeight="600" lineHeight="1.1em">{album.name}</Text>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="xs">{"+" + album.minimumage}</Tag>
                                            <Text fontSize="xs">{album?.venue?.name}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    }) : <>
                        <Text>Todavía no hay álbums disponibles.</Text>
                    </>
                }
            </Box>
        </>
    );
}

export default Albums;