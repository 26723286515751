import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  Switch,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
  Checkbox,
  VStack, // Importar Checkbox y VStack de Chakra UI para gestionar la lista de promotores
  Select
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { appState, userState } from '../Recoil/atoms';

import Dropzone from './DropZone';
import dateHandler from '../Handlers/dateHandler';

import { AuthContext } from '../context/AuthContext';

const EditEventForm = ({ event }) => {

  const { logout } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [formData, setFormData] = useState({
    name: '',
    start_date_time: '',
    end_date_time: '',
    flyer: '',
    dresscode: '',
    minimumage: '',
    description: '',
    guest_list_info: '',
    guest_lisr_closes: '',
    guest_until: '',
    free_until: '',
    tickets_link: '',
    guest_list: true,
    sold_out: false,
    active: false,
    venueId: '',
    promoterIds: [], // Cambiar a un array para manejar múltiples promotores
  });

  const [venues, setVenues] = useState([]);
  const [promoters, setPromoters] = useState([]);

  useEffect(() => {
    if (event) {
      setFormData({
        name: event.name || '',
        start_date_time: event.start_date_time || '',
        end_date_time: event.end_date_time || '',
        flyer: event.flyer || '',
        dresscode: event.dresscode || '',
        minimumage: event.minimumage || '',
        description: event.description || '',
        guest_list_info: event.guest_list_info || '',
        guest_list_closes: event.guest_list_closes || '',
        guest_until: event.guest_until || '',
        free_until: event.free_until || '',
        tickets_link: event.tickets_link || '',
        guest_list: event.guest_list || true,
        sold_out: event.sold_out || false,
        active: event.active || false,
        venueId: event.venue?.id || '',
        promoterIds: event.promoters?.map(p => p.id) || [], // Obtener los IDs de los promotores
      });
    }
  }, [event]);

  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const fetchData = async () => {
    try {
      if (currentUser.role === 'masteruser' || currentUser.role === 'admin') {
        const venuesData = await apiHandler.getAccessibleVenues(logout);
        const promotersData = await apiHandler.getAccessiblePromoters(logout);
        setVenues(venuesData);
        setPromoters(promotersData);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Verificar si el campo es de tipo datetime-local
    if (type === 'datetime-local') {
      // Convertir el valor al formato completo (incluyendo segundos y milisegundos)
      const selectedDate = new Date(value);
      setFormData({
        ...formData,
        [name]: selectedDate.toISOString(), // Guardar la fecha completa en formato UTC
      });
    } else {
      // Para otros tipos de inputs (incluidos checkboxes)
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleCheckboxChange = (promoterId) => {
    setFormData((prevFormData) => {
      const isChecked = prevFormData.promoterIds.includes(promoterId);
      const newPromoterIds = isChecked
        ? prevFormData.promoterIds.filter(id => id !== promoterId)
        : [...prevFormData.promoterIds, promoterId];

      return { ...prevFormData, promoterIds: newPromoterIds };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.venueId || formData.promoterIds.length === 0) {
      alert('Debes seleccionar un Venue y al menos un Promotor.');
      return;
    }

    const updatedEvent = await updateEvent(formData);
    window.location.href = '/events';
  };

  const updateEvent = async (formData) => {
    try {
      setLoading(true);
      const eventResponse = await apiHandler.updateEvent(event.id, formData, logout);
      if (eventResponse.error) {
        alert(eventResponse.error);
        setLoading(false);
        return eventResponse.error;
      }
      alert('Evento actualizado correctamente');
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleFileSubmit = async (dropFile) => {
    setLoading(true);
    const fileForm = new FormData();
    await fileForm.append('file', dropFile[0]);

    try {
      const response = await apiHandler.uploadFile(fileForm, logout);
      await setFormData({
        ...formData,
        flyer: response.filePath,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteEvent = async () => {
    try {
      setLoading(true);
      const eventResponse = await apiHandler.deleteEvent(event.id, logout);
      if (eventResponse.error) {
        alert(eventResponse.error);
        setLoading(false);
        return eventResponse.error;
      }
      alert('Evento eliminado correctamente');
      setLoading(false);
      window.location.href = '/events';
    } catch (error) {
      console.error('Error al eliminar evento:', error);
      setLoading(false);
    }
  };

  const handleDeleteFlyer = async () => {
    try {
      await setFormData({
        ...formData,
        flyer: null,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">EDITAR EVENTO</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="active" mb="0">
              Evento publicado
            </FormLabel>
            <Switch
              id="active"
              name="active"
              isChecked={formData.active}
              onChange={handleChange}
              colorScheme="blackScheme"
            />
          </FormControl>

          <FormControl id="name">
            <FormLabel>Nombre</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="start_date_time">
            <FormLabel>Fecha de inicio</FormLabel>
            <Input
              type="datetime-local"
              name="start_date_time"
              value={dateHandler.toInputDate(formData.start_date_time)}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="end_date_time">
            <FormLabel>Fecha de fin</FormLabel>
            <Input
              type="datetime-local"
              name="end_date_time"
              value={dateHandler.toInputDate(formData.end_date_time)}
              onChange={handleChange}
            />
          </FormControl>

          {/* Mostrar la vista previa del flyer si existe */}
          {formData.flyer && (
            <Box>
              <FormLabel>Flyer Actual</FormLabel>
              <Image src={formData.flyer} alt="Flyer" borderRadius="md" maxW="100%" />
            </Box>
          )}

          <FormControl id="file" isRequired>
            <FormLabel>Flyer</FormLabel>
            <Dropzone onDrop={handleFileSubmit} existingFiles={formData.flyer ? [{url: formData.flyer}] : []} onRemoveExisting={handleDeleteFlyer} multiple={false} />
          </FormControl>

          <FormControl id="dresscode">
            <FormLabel>Código de vestimenta</FormLabel>
            <Input
              type="text"
              name="dresscode"
              value={formData.dresscode}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="minimumage">
            <FormLabel>Edad mínima</FormLabel>
            <Input
              type="number"
              name="minimumage"
              value={formData.minimumage}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="description">
            <FormLabel>Descripción</FormLabel>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_list_info">
            <FormLabel>Información de lista de invitados</FormLabel>
            <Textarea
              name="guest_list_info"
              value={formData.guest_list_info}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_list_closes">
            <FormLabel>Cierre lista</FormLabel>
            <Input
              type="datetime-local"
              name="guest_list_closes"
              value={dateHandler.toInputDate(formData.guest_list_closes)}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="guest_until">
            <FormLabel>Hora fin lista descuento</FormLabel>
            <Input
              type="time"
              name="guest_until"
              value={formData.guest_until}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="free_until">
            <FormLabel>Hora fin lista gratis</FormLabel>
            <Input
              type="time"
              name="free_until"
              value={formData.free_until}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="tickets_link">
            <FormLabel>Enlace de entradas</FormLabel>
            <Input
              type="text"
              name="tickets_link"
              value={formData.tickets_link}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="sold_out" mb="0">
              Sold Out
            </FormLabel>
            <Switch
              id="sold_out"
              name="sold_out"
              isChecked={formData.sold_out}
              onChange={handleChange}
              colorScheme="blackScheme"
            />
          </FormControl>

          <FormControl id="promoterIds">
            <FormLabel>Grupos Promotores</FormLabel>
            <VStack align="start">
              {promoters.map((promoter) => (
                <Checkbox
                  key={promoter.id}
                  value={promoter.id}
                  isChecked={formData.promoterIds.includes(promoter.id)}
                  onChange={() => handleCheckboxChange(promoter.id)}
                >
                  {promoter.name}
                </Checkbox>
              ))}
            </VStack>
          </FormControl>

          <FormControl id="venueId" isRequired>
            <FormLabel>Local</FormLabel>
            <Select
              name="venueId"
              value={formData.venueId}
              onChange={handleChange}
              placeholder="Selecciona un Local"
            >
              {venues.map(venue => (
                <option key={venue.id} value={venue.id}>{venue.name}</option>
              ))}
            </Select>
          </FormControl>

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            Actualizar evento
          </Button>

          {(currentUser.role === 'masteruser' || currentUser.role === 'admin') && (
            <Button
              colorScheme="red"
              borderRadius="full"
              variant='solid'
              onClick={onOpen}
            >
              Eliminar Evento
            </Button>
          )}
        </Stack>
      </form>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Eliminar Evento
            </AlertDialogHeader>

            <AlertDialogBody>
              ¿Estás seguro de que deseas eliminar este evento? Esta acción no se puede deshacer.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteEvent} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditEventForm;