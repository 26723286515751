import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import apiHandler from '../Handlers/apiHandler';
import dateHandler from '../Handlers/dateHandler';

import { brandState, appState, eventsState, userState } from '../Recoil/atoms';

import { Box, Image, Card, CardHeader, CardBody, CardFooter, Stack, Button, useTheme, Tag, Badge, Text, HStack } from '@chakra-ui/react';

import utilHandlers from '../Handlers/utilHandlers';

import { AuthContext } from '../context/AuthContext';

const Events = () => {

    const { logout } = useContext(AuthContext);

    const [brandData, setBrandData] = useRecoilState(brandState);
    const [isLoading, setLoading] = useRecoilState(appState);
    const [currentEvents, setCurrentEvents] = useRecoilState(eventsState);
    const [currentUser, setCurrentUser] = useRecoilState(userState);

    const [whenEvents, setWhenEvents] = useState('upcoming');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchFutureEvents = async () => {
            try {
                setLoading(true);
                const events = await apiHandler.getPublicEvents(brandData?.type, brandData?.result.id, whenEvents == 'now' ? 'upcoming' : whenEvents, logout);
                const now = new Date();
                let sortedEvents = whenEvents == 'upcoming' 
                    ? events.sort((a, b) => new Date(a.start_date_time) - new Date(b.start_date_time)) 
                    : whenEvents == 'now' 
                    ? events.filter(event => new Date(event.start_date_time) <= now).sort((a, b) => new Date(a.start_date_time) - new Date(b.start_date_time)) 
                    : events.sort((a, b) => new Date(b.start_date_time) - new Date(a.start_date_time));
                await setCurrentEvents(sortedEvents);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        }

        fetchFutureEvents();

    }, [brandData, whenEvents]);


    return (
        <>
            <Box padding="10px"  w="100%" display="flex" flexDir="column" gap="20px" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" alignItems="center">
                {
                    currentUser && currentUser.role != 'client' && <>
                        <Box gap="10px" display="flex" justifyContent="center" padding="10px" borderRadius="full" borderWidth="2px" borderColor="whiteScheme.400" bg="whiteScheme.400">
                            <Button
                                variant={ whenEvents == 'upcoming' ? 'solid' : 'outline'}
                                borderRadius="full"
                                colorScheme="blackScheme"
                                onClick={() => setWhenEvents('upcoming')}
                                size='sm'
                            >
                                Próximos
                            </Button>
                            <Button
                                variant={ whenEvents == 'past' ? 'solid' : 'outline'}
                                onClick={() => setWhenEvents('past')}
                                borderRadius="full"
                                colorScheme="blackScheme"
                                size='sm'
                            >
                                Pasados
                            </Button>
                            <Button
                                variant={ whenEvents == 'now' ? 'solid' : 'outline'}
                                onClick={() => setWhenEvents('now')}
                                borderRadius="full"
                                colorScheme="blackScheme"
                                size='sm'
                            >
                                Now
                            </Button>
                        </Box>
                    </>
                }
                {
                    currentEvents ? currentEvents.map(event => {
                        const weekDay = dateHandler.toEsDate(event.start_date_time, 'weekDay');
                        const day = dateHandler.toEsDate(event.start_date_time, 'day');
                        const month = dateHandler.toEsDate(event.start_date_time, 'month');
                        const year = dateHandler.toEsDate(event.start_date_time, 'year');

                        const FormattedDate = () => {
                            return (
                                <span>
                                    {weekDay} <strong>{day}</strong> {month}. {year}
                                </span>
                            );
                        }

                        const eventFlyer = event?.flyer;

                        return (
                            <Box position="relative" w="100%" overflow="hidden" borderColor="whiteScheme.300" borderWidth="2px" borderRadius="20px" bgColor="whiteScheme.100" display='flex' key={event.id} onClick={() => {navigate(`/event/${event.id}`)}} opacity={event.active ? 1 : 0.5}>
                                {
                                    (eventFlyer && !utilHandlers.isMp4(eventFlyer)) && <>
                                        <Image src={eventFlyer} maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{ aspectRatio: 1080 / 1350 }}/>
                                    </>
                                }
                                {
                                    (eventFlyer && utilHandlers.isMp4(eventFlyer)) && <>
                                        <Box as="video" autoPlay loop muted playsInline maxW="33%" w="100%" h="100%" objectFit="cover" zIndex="1" style={{ aspectRatio: 1080 / 1350 }}>
                                            <source src={eventFlyer} type="video/mp4" />
                                        </Box>
                                    </>
                                }
                                <Box padding="10px" display="flex" gap="5px" alignItems="start" flexDirection="column">
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Tag bgColor="blackScheme.300" color="whiteScheme.300" fontSize="xs"><FormattedDate/></Tag>
                                        <Text fontSize="xs">{dateHandler.toTime(event.start_date_time)} - {dateHandler.toTime(event.end_date_time)}</Text>
                                    </Box>
                                    <Box display="flex" flexDirection="column" gap="10px">
                                        <Text fontSize="2xl" fontWeight="600" lineHeight="1.1em">{event.name}</Text>
                                        <Box display="flex" gap="10px" alignItems="center">
                                            <Tag bgColor="whiteScheme.400" color="blackScheme.400" fontSize="xs">{"+" + event.minimumage}</Tag>
                                            <Text fontSize="xs">{event?.venue?.name}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    }) : <></>
                }
            </Box>
        </>
    );
}

export default Events;