import React, { useState, useContext } from 'react';
import { Box, Button, FormControl, FormLabel } from '@chakra-ui/react';
import MyDropzone from './DropZone'; // Importar tu componente personalizado
import apiHandler from '../Handlers/apiHandler';

import { AuthContext } from '../context/AuthContext';

const AlbumUploader = ({ existingImages = [], onUpload, onDeleteImage = () => {}, event }) => {
  
  const { logout } = useContext(AuthContext);

  const [newImages, setNewImages] = useState([]); // Imágenes nuevas para subir
  const [images, setImages] = useState(existingImages); // Imágenes existentes
  const [loading, setLoading] = useState({}); // Estado de carga para cada imagen
  const [changedOriginal, setChangedOriginal] = useState(false);

  // Manejar las imágenes agregadas a través de tu Dropzone personalizado
  const handleDrop = (acceptedFiles) => {
    setNewImages((prevImages) => [
      ...prevImages,
      ...acceptedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        fileName: file.name // Almacenar el nombre del archivo para el estado de carga
      }))
    ]);
  };

  // Eliminar imagen nueva antes de subir
  const removeNewImage = (file) => {
    setNewImages((prevFiles) => prevFiles.filter((f) => f.file !== file));
  };

  // Eliminar una imagen existente del álbum
  const handleDeleteImage = (file) => {
    onDeleteImage(file.id); // Llamada al prop para eliminar la imagen
    setImages((images) => images.filter((img) => img.id !== file.id));
    setChangedOriginal(true);
  };

  // Eliminar imagen de Dropzone
  const handleRemoveFromDropzone = (file) => {
    removeNewImage(file);
  };

  // Función para actualizar el estado de carga de una imagen
  const setFileLoading = (fileName, isLoading) => {
    setLoading((prevLoading) => ({ ...prevLoading, [fileName]: isLoading }));
  };

  // Función que se ejecuta al dar clic en el botón "Subir imágenes"
  const handleUpload = async () => {
    let finalEventImages = [];

    if (newImages.length > 0) {
      // Establecer loading para todas las imágenes
      setLoading((prevLoading) => ({ ...prevLoading, all: true }));
      
      let uploadedImagesIds = []; // Variable para acumular los IDs de las imágenes subidas

      for (const image of newImages) {
        const fileForm = new FormData();
        fileForm.append('files', image.file);

        // Establecer el estado de carga del archivo
        setFileLoading(image.fileName, true);

        try {
          // Subir la imagen a la API
          const response = await apiHandler.uploadImageFiles(fileForm, logout);

          // Suponiendo que la API devuelve un array de IDs de imágenes en 'response.data.imageIds'
          if (response?.imageIds) {
            uploadedImagesIds = [...uploadedImagesIds, ...response.imageIds]; // Añadir los IDs de las imágenes
          }

          // Marcar el archivo como cargado
          setFileLoading(image.fileName, false);
        } catch (error) {
          console.error(error);
          // Marcar el archivo como no cargado si hay un error
          setFileLoading(image.fileName, false);
        }
      }

      finalEventImages = [...uploadedImagesIds];

      // Una vez que todas las imágenes se han cargado, establecer loading.all en false
      setLoading((prevLoading) => ({ ...prevLoading, all: false }));
      setNewImages([]); // Limpiar las imágenes nuevas después de subirlas
    }

    if(images.length > 0) {
      const changedImages = images.map(image => image.id);

      finalEventImages = [...finalEventImages, ...changedImages];
    }

    if(finalEventImages?.length > 0 || changedOriginal) {
      try {
        const eventData = {
          images: finalEventImages, // Añadir los IDs de las imágenes
          // Puedes añadir más datos al evento si es necesario
        };

        // Llamada a la API para actualizar el evento
        const theResponse = await apiHandler.updateEventAlbum(event?.id, eventData, logout);
        if (theResponse.id) {
          alert('Album actualizado correctamente.');
          window.location.href = `/event/${event.id}`;;
        }
      } catch (error) {
        console.error('Error al actualizar el evento:', error);
      }
    }

  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
      w="100%" // Ajustar al ancho completo del contenedor
    >
      {/* Formulario para subir imágenes */}
      <FormControl w="100%">
        <FormLabel>Álbum de fotos</FormLabel>
        <MyDropzone
          onDrop={handleDrop}
          onRemove={handleRemoveFromDropzone}
          onRemoveExisting={handleDeleteImage}
          existingFiles={images}
          multiple={true}
          loading={loading} // Pasar el estado de carga al DropZone
          setFileLoading={setFileLoading} // Pasar la función de estado de carga al DropZone
        />
      </FormControl>

      {/* Botón para subir imágenes nuevas */}
      <Button
        type="button"
        borderRadius="full"
        variant="solid"
        colorScheme="whiteScheme"
        mt={5}
        w="100%" // Para que coincida con el estilo del botón de editar evento
        onClick={handleUpload}
      >
        Actualizar Album
      </Button>
    </Box>
  );
};

export default AlbumUploader;