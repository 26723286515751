import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Checkbox,
  Text,
  VStack,
  Switch,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { appState, userState } from '../Recoil/atoms';
import Dropzone from './DropZone';

import { AuthContext } from '../context/AuthContext';

const CreateVenueForm = () => {

  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    free_until: '',
    guest_until: '',
    list_closes: '',
    list_closes_pre: false,
    url: '',
    events_media: '',
    pictures_media: '',
    logo_media: '',
    vip_tables_contact: '',
    promoterIds: [],
  });

  const [promoters, setPromoters] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promotersData = await apiHandler.getAccessiblePromoters(logout);
        setPromoters(promotersData);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCheckboxChange = (selectedValue) => {
    setFormData((prevFormData) => {
      const currentValues = prevFormData.promoterIds;
      const isAlreadySelected = currentValues.includes(selectedValue);

      const newValues = isAlreadySelected
        ? currentValues.filter((value) => value !== selectedValue)
        : [...currentValues, selectedValue];

      return {
        ...prevFormData,
        promoterIds: newValues,
      };
    });
  };

  const handleFileSubmit = async (file, fieldName) => {
    setLoading(true);
    const fileForm = new FormData();
    await fileForm.append('file', file[0]);

    try {
      const response = await apiHandler.uploadFile(fileForm, logout);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: response.filePath,
      }));
    } catch (error) {
      console.error('Error al subir el archivo:', error);
    }
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = ['name', 'url', 'events_media', 'pictures_media', 'logo_media'];

    for (let field of requiredFields) {
      if (!formData[field]) {
        alert(`El campo ${field} es obligatorio`);
        return;
      }
    }

    const newVenue = await createVenue(formData);
  };

  const createVenue = async (formData) => {
    try {
      setLoading(true);
      const venue = await apiHandler.createVenue(formData, logout);
      if (venue.error) {
        alert(venue.error);
        setLoading(false);
        return venue.error;
      }
      alert('Venue creado correctamente');
      setLoading(false);
      navigate('/venues');
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">CREAR VENUE</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">

          <FormControl id="name" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="address">
            <FormLabel>Dirección</FormLabel>
            <Input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="free_until">
            <FormLabel>Hora fin lista gratis</FormLabel>
            <Input
              type="time"
              name="free_until"
              value={formData.free_until}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="guest_until">
            <FormLabel>Hora fin lista de invitados</FormLabel>
            <Input
              type="time"
              name="guest_until"
              value={formData.guest_until}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="list_closes">
            <FormLabel>Hora de cierre de la lista</FormLabel>
            <Input
              type="time"
              name="list_closes"
              value={formData.list_closes}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="list_closes_pre" mb="0">
              Lista cierra antes
            </FormLabel>
            <Switch
              id="list_closes_pre"
              name="list_closes_pre"
              isChecked={formData.list_closes_pre}
              onChange={handleInputChange}
              colorScheme="blackScheme"
            />
          </FormControl>

          <FormControl id="url" isRequired>
            <FormLabel>URL</FormLabel>
            <Input
              type="text"
              name="url"
              value={formData.url}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="events_media" isRequired>
            <FormLabel>Media de Eventos</FormLabel>
            <Dropzone onDrop={(files) => handleFileSubmit(files, 'events_media')} />
          </FormControl>

          <FormControl id="pictures_media" isRequired>
            <FormLabel>Media de Imágenes</FormLabel>
            <Dropzone onDrop={(files) => handleFileSubmit(files, 'pictures_media')} />
          </FormControl>

          <FormControl id="logo_media" isRequired>
            <FormLabel>Logo Media</FormLabel>
            <Dropzone onDrop={(files) => handleFileSubmit(files, 'logo_media')} />
          </FormControl>

          <FormControl id="vip_tables_contact">
            <FormLabel>Contacto para Mesas VIP</FormLabel>
            <Input
              type="text"
              name="vip_tables_contact"
              value={formData.vip_tables_contact}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="promoterIds">
            <FormLabel>Grupo Promotor</FormLabel>
            <VStack align="start">
              {promoters.map((promoter) => (
                <Checkbox
                  key={promoter.id}
                  value={promoter.id}
                  isChecked={formData.promoterIds.includes(promoter.id)}
                  onChange={() => handleCheckboxChange(promoter.id)}
                >
                  {promoter.name}
                </Checkbox>
              ))}
            </VStack>
          </FormControl>

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            Crear Venue
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default CreateVenueForm;