import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Checkbox,
  Text,
  VStack,
  Select,
  HStack,
  NumberInput,
  NumberInputField,
  IconButton
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import apiHandler from '../Handlers/apiHandler';
import { useRecoilState } from 'recoil';
import { appState, userState, pageState } from '../Recoil/atoms';

import { AuthContext } from '../context/AuthContext';

const CreateUserForm = ({ doAfterLogin }) => {

  const { logout } = useContext(AuthContext);
  
  const navigate = useNavigate();
  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);
  const [currentPage, setPage] = useRecoilState(pageState);

  const initialFormData = {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    name: '',
    surnames: '',
    phone: '',
    birthdate: '',
    location: '',
    refId: currentUser.id,
    venueIds: [],
    promoterIds: [],
    eventIds: [],
    role: 'rrpp',
    user_can: [], // Añadido para los permisos personalizados
  };

  const [formData, setFormData] = useState(initialFormData);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [rrpp, setRRPP] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState('');
  const [selectedPermission, setSelectedPermission] = useState('checkin');
  const [setFreeValue, setSetFreeValue] = useState(0);

  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const fetchData = async () => {
    try {
      if (currentUser.role === 'masteruser' || currentUser.role === 'admin') {
        const eventsData = await apiHandler.getAccessibleEvents(logout);
        const venuesData = await apiHandler.getAccessibleVenues(logout);
        const promotersData = await apiHandler.getAccessiblePromoters(logout);
        const rrppData = await apiHandler.getAccessibleRRPP(logout);
        setEvents(eventsData);
        setVenues(venuesData);
        setPromoters(promotersData);
        setRRPP(rrppData);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleCheckboxChange = (selectedValue, field) => {
    setFormData((prevFormData) => {
      const currentValues = prevFormData[field];
      const isAlreadySelected = currentValues.includes(selectedValue);

      const newValues = isAlreadySelected
        ? currentValues.filter((value) => value !== selectedValue)
        : [...currentValues, selectedValue];

      return {
        ...prevFormData,
        [field]: newValues,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddPermission = () => {
    if (!selectedVenue && selectedPermission != 'seeinactive') {
      alert('Selecciona un Local para asignar permisos.');
      return;
    }

    let newPermission = {};

    if (selectedPermission === 'checkin') {
      newPermission = { filter: 'venue', filterId: selectedVenue, can: 'checkin', value: null };
    } else if (selectedPermission === 'setfree') {
      newPermission = { filter: 'venue', filterId: selectedVenue, can: 'setfree', value: setFreeValue };
    } else if (selectedPermission === 'unsetfree') {
      newPermission = { filter: 'venue', filterId: selectedVenue, can: 'unsetfree', value: null };
    } else if(selectedPermission == 'seeall') {
      newPermission = { filter: 'venue', filterId: selectedVenue, can: 'seeall', value: null };
    } else if(selectedPermission == 'seecount') {
      newPermission = { filter: 'venue', filterId: selectedVenue, can: 'seecount', value: null };
    } else if(selectedPermission == 'seeinactive') {
      newPermission = { filter: null, filterId: null, can: 'seeinactive', value: null };
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      user_can: [...prevFormData.user_can, newPermission],
    }));

    // Reset form controls
    setSelectedVenue('');
    setSetFreeValue(0);
  };

  const handleRemovePermission = (index) => {
    setFormData((prevFormData) => {
      const updatedUserCan = [...prevFormData.user_can];
      updatedUserCan.splice(index, 1);
      return { ...prevFormData, user_can: updatedUserCan };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      'email', 'confirmEmail', 'password', 'confirmPassword', 'name',
      'phone', 'birthdate', 'location'
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        alert(`El campo ${field} es obligatorio`);
        return;
      }
    }

    // Validar email y confirmEmail
    if (formData.email !== formData.confirmEmail) {
      setEmailError('Los correos electrónicos no coinciden');
      return;
    } else {
      setEmailError('');
    }

    // Validar password y confirmPassword
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Las contraseñas no coinciden');
      return;
    } else {
      setPasswordError('');
    }

    // Validar que al menos un promoter, event o venue esté asignado para 'masteruser' y 'admin'
    if (currentUser.role === 'masteruser' || currentUser.role === 'admin') {
      if (formData.promoterIds.length === 0 && formData.eventIds.length === 0 && formData.venueIds.length === 0) {
        alert('Debes asignar al menos un Evento, Local o Grupo Promotor.');
        return;
      }
    }

    const newUser = await createUser(formData);
  };

  const createUser = async (formData) => {
    try {
      setLoading(true);
      const user = await apiHandler.createCustomUser(formData, logout);
      if (user.error) {
        alert(user.error);
        setLoading(false);
        return user.error;
      }
      alert('Usuario creado correctamente');
      setFormData(initialFormData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Box
      p="10px"
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="20px"
    >
      <Text fontSize="2xl" fontWeight="600" textTransform="uppercase" marginTop="20px">CREAR USUARIO</Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="20px">
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="confirmEmail" isRequired>
            <FormLabel>Confirmar Email</FormLabel>
            <Input
              type="email"
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleInputChange}
            />
            {emailError && <Text color="red.500">{emailError}</Text>}
          </FormControl>

          <FormControl id="password" isRequired>
            <FormLabel>Contraseña</FormLabel>
            <Input
              type="text"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="confirmPassword" isRequired>
            <FormLabel>Confirmar Contraseña</FormLabel>
            <Input
              type="text"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
            {passwordError && <Text color="red.500">{passwordError}</Text>}
          </FormControl>

          <FormControl id="name" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="surnames">
            <FormLabel>Apellidos</FormLabel>
            <Input
              type="text"
              name="surnames"
              value={formData.surnames}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="phone">
            <FormLabel>Teléfono</FormLabel>
            <Input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="birthdate" isRequired>
            <FormLabel>Fecha de Nacimiento</FormLabel>
            <Input
              type="date"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="location">
            <FormLabel>Localidad</FormLabel>
            <Input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
            />
          </FormControl>

          {/* Rol de usuario */}
          <FormControl id="role">
            <FormLabel>Rol de usuario</FormLabel>
            <Select
              name="role"
              value={formData.role}
              onChange={handleInputChange}
            >
              {currentUser.role === 'masteruser' && (
                <>
                  <option value="masteruser">Master User</option>
                  <option value="admin">Admin</option>
                  <option value="rrpp">RRPP</option>
                  <option value="comm">Promotor</option>
                  <option value="user">Usuario</option>
                </>
              )}
              {currentUser.role === 'admin' && (
                <>
                  <option value="rrpp">RRPP</option>
                  <option value="comm">Promotor</option>
                  <option value="user">Usuario</option>
                </>
              )}
              {currentUser.role === 'rrpp' && (
                <option value="comm">Promotor</option>
              )}
            </Select>
          </FormControl>

          {/* Selección de RRPP para asignar refId - Solo visible para masteruser y admin */}
          {(currentUser.role === 'masteruser' || currentUser.role === 'admin') && (
            <FormControl id="refId">
              <FormLabel>Asignar a Usuario</FormLabel>
              <Select
                name="refId"
                value={formData.refId}
                onChange={handleInputChange}
              >
                <option value="">Selecciona un Usuario</option>
                {rrpp && rrpp.map(rrppItem => (
                  <option key={rrppItem.id} value={rrppItem.id}>{rrppItem.name} {rrppItem.surnames}</option>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Selección de Evento, Local y Grupo Promotor */}
          {(currentUser.role === 'masteruser' || currentUser.role === 'admin') && (
            <>
              <FormControl id="promoterIds">
                <FormLabel>Grupo Promotor</FormLabel>
                <VStack align="start">
                  {promoters.map((promoter) => (
                    <Checkbox
                      key={promoter.id}
                      value={promoter.id}
                      isChecked={formData.promoterIds.includes(promoter.id)}
                      onChange={() => handleCheckboxChange(promoter.id, 'promoterIds')}
                    >
                      {promoter.name}
                    </Checkbox>
                  ))}
                </VStack>
              </FormControl>

              <FormControl id="venueIds">
                <FormLabel>Local</FormLabel>
                <VStack align="start">
                  {venues.map((venue) => (
                    <Checkbox
                      key={venue.id}
                      value={venue.id}
                      isChecked={formData.venueIds.includes(venue.id)}
                      onChange={() => handleCheckboxChange(venue.id, 'venueIds')}
                    >
                      {venue.name}
                    </Checkbox>
                  ))}
                </VStack>
              </FormControl>

              <FormControl id="eventIds">
                <FormLabel>Evento</FormLabel>
                <VStack align="start">
                  {events.map((event) => (
                    <Checkbox
                      key={event.id}
                      value={event.id}
                      isChecked={formData.eventIds.includes(event.id)}
                      onChange={() => handleCheckboxChange(event.id, 'eventIds')}
                    >
                      {event.name} - {event.venue.name}
                    </Checkbox>
                  ))}
                </VStack>
              </FormControl>
            </>
          )}

          {/* Permisos personalizados */}
          <FormControl id="user_can">
            <FormLabel>Permisos personalizados</FormLabel>
            <VStack spacing={3}>
              <Select
                value={selectedPermission}
                onChange={(e) => setSelectedPermission(e.target.value)}
              >
                <option value="checkin">Checkin</option>
                <option value="setfree">Set Free</option>
                <option value="setfree">Unset Free</option>
                <option value="seeall">See All</option>
                <option value="seecount">See Count</option>
                <option value="seeinactive">See Inactive</option>
              </Select>
              {
                selectedPermission != 'seeinactive' &&
                  <Select
                    value={selectedVenue}
                    onChange={(e) => setSelectedVenue(e.target.value)}
                    placeholder="Selecciona un Local"
                  >
                    {venues.map((venue) => (
                      <option key={venue.id} value={venue.id}>{venue.name}</option>
                    ))}
                  </Select>
              }
              {selectedPermission === 'setfree' && (
                <NumberInput
                  min={0}
                  value={setFreeValue}
                  onChange={(valueString) => setSetFreeValue(parseInt(valueString, 10))}
                >
                  <NumberInputField />
                </NumberInput>
              )}
              <Button borderRadius="full" onClick={handleAddPermission} colorScheme="blackScheme">Añadir Permiso</Button>
            </VStack>
            <VStack align="start" marginTop="10px">
              {formData.user_can.map((permission, index) => (
                <HStack key={index} width="100%" justifyContent="space-between">
                  <Text>
                    {`${permission.filter ? venues.find(venue => venue.id == permission.filterId)?.name + ' - ' : ''}${permission.can}${permission.value !== null ? ` - ${permission.value}` : ''}`}
                  </Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => handleRemovePermission(index)}
                  />
                </HStack>
              ))}
            </VStack>
          </FormControl>

          <Button type="submit" borderRadius="full" variant='solid' colorScheme='whiteScheme'>
            Crear usuario
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default CreateUserForm;