export const isMp4 = (url) => {
    return url.toLowerCase().endsWith('.mp4') || url.toLowerCase().endsWith('.mov');
}

export const doesUserCan = (user_can, can, filter, filterId) => {
    try {
        if(!user_can) return false;

        for (let perm of user_can) {
            const canMatch = perm.can == can;
            const filterMatch = perm.filter == filter;
            const filterIdMatch = perm.filterId == filterId;

            if (canMatch && filterMatch && filterIdMatch) {
                // Si perm.value existe, lo retornamos inmediatamente
                if (perm.value) {
                    return perm.value;
                }
                // Si no hay valor, pero las condiciones coinciden, retornamos true
                return true;
            }
        }
    } catch (error) {
        console.error(`Error en doUserCan: ${error}`);
        return false;
    }
}

const utilHandlers = {
    isMp4,
    doesUserCan
}

export default utilHandlers;