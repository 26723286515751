import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Text,
  Tag,
  Input,
  FormControl,
  VStack,
  Wrap
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import apiHandler from '../Handlers/apiHandler';
import { appState, userState, venueToEditState } from '../Recoil/atoms'; // Importa el estado `venueToEditState`

import { AuthContext } from '../context/AuthContext';

const MyVenues = () => {

  const { logout } = useContext(AuthContext);

  const [isLoading, setLoading] = useRecoilState(appState);
  const [currentUser] = useRecoilState(userState);
  const [myVenues, setMyVenues] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [venueToEdit, setVenueToEdit] = useRecoilState(venueToEditState); // Estado para almacenar el venue seleccionado
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchMyVenues();
    setLoading(false);
  }, []);

  const fetchMyVenues = async () => {
    try {
      setLoading(true);
      const venues = await apiHandler.getAccessibleVenues(logout);
      if (!venues.error) {
        setMyVenues(venues);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const normalizeText = (text) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchString(normalizeText(value));
  };

  const handleVenueClick = (venue) => {
    setVenueToEdit(venue); // Almacena el venue seleccionado en el estado de Recoil
    navigate('/edit-venue'); // Redirige a la página de edición
  };

  const filteredVenues = myVenues?.filter((venue) => {
    const venueName = normalizeText(venue.name);
    const promoters = normalizeText(venue.promoters.map(promoter => promoter.name).join(' '));
    const searchTerms = searchString.split(' ')?.filter(term => term);

    return searchTerms.every(term =>
      venueName.includes(term) ||
      promoters.includes(term)
    );
  });

  return (
    <>
      {
        (currentUser && myVenues) && <Box p="10px">
          <FormControl id="search" width="100%" mb="20px">
            <Input
              type="text"
              name="search"
              value={searchString}
              onChange={handleSearch}
              placeholder="Buscar por nombre de venue o promotor..."
              borderRadius="full"
            />
          </FormControl>

          <VStack spacing={4} align="stretch">
            {filteredVenues.length > 0 ? (
              filteredVenues?.map((venue) => (
                <Box
                  key={venue.id}
                  w="100%"
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  mb={2}
                  cursor="pointer"
                  onClick={() => handleVenueClick(venue)} // Maneja el clic en el venue
                >
                  <Text fontSize="md" fontWeight="600">{venue.name}</Text>
                  <Wrap mt={2} spacing={2}>
                    {venue.promoters.length > 0 && venue.promoters.map((promoter) => (
                      <Tag key={promoter.id} fontSize="sm" colorScheme="green">
                        {promoter.name}
                      </Tag>
                    ))}
                  </Wrap>
                </Box>
              ))
            ) : (
              <Text>No tienes venues asignados.</Text>
            )}
          </VStack>
        </Box>
      }
    </>
  );
};

export default MyVenues;